import { Dialog, DialogContent } from "../ui/dialog";
import { Input } from "../ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import CatalogImage from "@/assets/images/Catalog graphic.jpg";
import { Button } from "../ui/button";
import { useQuery, useQueryClient } from "react-query";
import { QueryKeys, fetchMakes } from "@/lib/queries";
import { useState } from "react";
import { getYearList } from "@/utils/helpers/getYearList";
import { useNavigate } from "react-router";

interface SearchProductsDialogProps {
  open: boolean;
  onClose: () => void;
}

const SearchProductsDialog = ({ open, onClose }: SearchProductsDialogProps) => {
  const { data: makes } = useQuery(QueryKeys.MAKES, () => fetchMakes(), {
    refetchOnWindowFocus: false,
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [keyword, setKeyword] = useState<string>("");
  const [selectedMake, setSelectedMake] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [code, setCode] = useState<string>("");

  const yearList = getYearList(1949);

  const search = () => {
    if (!keyword && !selectedMake && !selectedYear && !code) return;

    let queryParams = new URLSearchParams();
    if (selectedMake) queryParams.append("make", selectedMake);
    if (selectedYear) queryParams.append("year", selectedYear);
    if (code) queryParams.append("item", code);
    if (keyword) queryParams.append("item", keyword);
    const queryString = queryParams.toString();

    navigate(`/search?${queryString}`);
    queryClient.invalidateQueries(QueryKeys.SEARCH_RESULTS);

    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px] max-h-[calc(100vh-4rem)] overflow-y-auto no-scrollbar">
        <div className="flex-grow mt-4">
          <form
            className="relative"
            onSubmit={(event) => {
              event.preventDefault();
              search();
            }}
          >
            <Input
              className="w-full border border-gray-300 rounded-[12px] pl-4 pr-10 py-2 focus:outline-none bg-[#D9D9D9] focus-visible:ring-0 focus-visible:ring-offset-0"
              placeholder="Enter a specific part or category"
              onChange={(f) => setKeyword(f.target.value)}
              value={keyword}
            />
          </form>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-5">
            <Select value={selectedMake} onValueChange={setSelectedMake}>
              <SelectTrigger className="w-[120px] border-black">
                <SelectValue placeholder="Make" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {makes &&
                    makes.length > 0 &&
                    makes.map((make, index) => (
                      <SelectItem key={index} value={make.make} className="uppercase">
                        {make.make}
                      </SelectItem>
                    ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <Select value={selectedYear} onValueChange={setSelectedYear}>
              <SelectTrigger className="w-[120px] border-black">
                <SelectValue placeholder="Year" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {yearList.map((year, index) => (
                    <SelectItem key={index} value={year.toString()}>
                      {year}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <Input
            placeholder="Item code"
            className=" w-28 border-black focus-visible:ring-0 focus-visible:ring-offset-0"
            onChange={(e) => setCode(e.target.value)}
            value={code}
          />
        </div>
        <div className="flex justify-center ">
          <Button
            type="button" // Change type to "button" to prevent default form submission
            className="bg-[#FFE512] h-10 w-28 hover:bg-[#ffe312d7] text-[#3D3D3D] font-black"
            onClick={search} // Manually trigger the search function
          >
            Search
          </Button>
        </div>

        <hr className="h-1 bg-black" />

        <h1 className="font-bold text-center">Search by Catalog</h1>
        <p className="font-light text-center max-w-sm mx-auto">
          If you don't know the particular Part name or Item code, you may search by browsing our online catalogue.
          {/* 
          <br />
          <br />
          Please select your desired Make from the menu selection below
          */}
        </p>
        <div className="flex justify-center">
          <img src={CatalogImage} alt="Catalog" className="w-4/5" />
        </div>
        <div className="flex justify-center my-2">
          {/* 
          <Select value={selectedMake} onValueChange={setSelectedMake}>
            <SelectTrigger className="w-[120px] border-black">
              <SelectValue placeholder="Make" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {makes &&
                  makes.length > 0 &&
                  makes.map((make, index) => (
                    <SelectItem key={index} value={make.make} className="uppercase">
                      {make.make}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          */}
          <Button
            className="bg-[#FFE512] w-[120px] text-black font-black hover:bg-[#ffe312d7]"
            onClick={(event) => {
              event.preventDefault();
              onClose();
              navigate("/catalog");
            }}
          >
            View Catalog
          </Button>
        </div>
        {/* 
        <div className="flex justify-center">
          <Button className="bg-[#FFE512] w-[120px] text-black hover:bg-[#ffe312d7]" onClick={search}>
            Search
          </Button>
        </div>
        */}
      </DialogContent>
    </Dialog>
  );
};

export default SearchProductsDialog;
