import { Card } from "@/components/ui/card";
import Banner from "./components/banner";
import { Link } from "react-router-dom";
import Ford from "@/assets/images/ford.svg";
import GM from "@/assets/images/gm.svg";
import Dodge from "@/assets/images/dodge.svg";
import licensedIMG from "@/assets/images/Licensed.png";
import timesavingIMG from "@/assets/images/TimeSaving.png";
import allsteelIMG from "@/assets/images/All-Steel.png";

const ClassicBodyPage = () => {
  return (
    <div>
      <Banner />
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 my-20 px-4 md:px-40 pb-20">
        <Card className="p-5 rounded-lg shadow-md shadow-gray-400 relative mx-14">
          <div className="flex justify-center my-10">
            <img src={Ford} alt="product" className="object-contain w-[90%] h-40" height={400} width={600} />
          </div>
          <h5 className="text-[#3D3D3D] text-3xl font-black mt-5 text-center">Ford</h5>
          <p className="text-[#3D3D3D] text-sm mt-2 text-center">Model</p>
          <Link className="absolute inset-0" to={"/classic-body/FORD"}>
            <span className="sr-only">View Classic Body</span>
          </Link>
        </Card>
        <Card className="p-5 rounded-lg shadow-md shadow-gray-400 relative mx-14">
          <div className="flex justify-center my-10">
            <img src={GM} alt="product" className="object-contain w-[90%] h-40" height={400} width={600} />
          </div>
          <h5 className="text-[#3D3D3D] text-3xl font-black mt-5 text-center">GM</h5>
          <p className="text-[#3D3D3D] text-sm mt-2 text-center">Model</p>
          <Link className="absolute inset-0" to={"/classic-body/GM"}>
            <span className="sr-only">View Classic Body</span>
          </Link>
        </Card>
        <Card className="p-5 rounded-lg shadow-md shadow-gray-400 relative mx-14">
          <div className="flex justify-center my-10">
            <img src={Dodge} alt="product" className="object-contain w-[90%] h-40" height={400} width={600} />
          </div>
          <h5 className="text-[#3D3D3D] text-3xl font-black mt-5 text-center">Dodge</h5>
          <p className="text-[#3D3D3D] text-sm mt-2 text-center">Model</p>
          <Link className="absolute inset-0" to={"/classic-body/DODGE"}>
            <span className="sr-only">View Classic Body</span>
          </Link>
        </Card>
      </div>
      <div className="space-y-16 md:max-w-5xl mx-auto">
        <div className="flex gap-10 items-center justify-between">
          <div className="h-52  w-52 rounded-full bg-white flex justify-center items-center">
            <img alt="product" className="rounded-full object-cover" src={licensedIMG} />
          </div>
          <div className="w-3/5">
            <div className="relative">
              <h1 className="z-[2] relative text-[#184178] text-5xl font-black">Licensed</h1>
              <span className="z-[1] absolute bg-[#FFE512]/40 h-7 w-full -bottom-[0.5rem] left-5" />
            </div>
            <p className="text-[#3D3D3D] text-lg mt-5 font-light max-w-lg">
              Dynacorn's replacement body shells are licensed through the meticulous standards set by the original
              manufacturers and licensed by either General Motors, Ford or FCA.
            </p>
          </div>
        </div>
        <div className="flex gap-10 items-center justify-between">
          <div className="h-52  w-52 rounded-full bg-white flex justify-center items-center">
            <img alt="product" className="rounded-full object-cover" src={allsteelIMG} />
          </div>
          <div className="w-3/5">
            <div className="relative">
              <h1 className="z-[2] relative text-[#184178] text-5xl font-black">All-Steel</h1>
              <span className="z-[1] absolute bg-[#FFE512]/40 h-7 w-full -bottom-[0.5rem] left-5" />
            </div>
            <p className="text-[#3D3D3D] text-lg mt-5 font-light max-w-lg">
              1006 Automotive Grade Steel. Our steel is thicker than the original body shell in most cases.
            </p>
          </div>
        </div>
        <div className="flex gap-10 items-center justify-between">
          <div className="h-52  w-52 rounded-full bg-[#D9D9D9] flex justify-center items-center">
            <img alt="product" className="rounded-full object-cover" src={timesavingIMG} />
          </div>
          <div className="w-3/5">
            <div className="relative">
              <h1 className="z-[2] relative text-[#184178] text-5xl font-black">Time saving</h1>
              <span className="z-[1] absolute bg-[#FFE512]/40 h-7 w-full -bottom-[0.5rem] left-5" />
            </div>
            <p className="text-[#3D3D3D] text-lg mt-5 font-light max-w-lg">
              All the hard work has been done in our state-of-the-art-facility. Everthing is fitted, assembled and
              welded so you save countless hours.
            </p>
          </div>
        </div>

        <div className="my-10 pt-10 px-4 md:px-8">
        <h1 className="text-center font-black">Recent news & media coverage</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 my-10">
          <div>
            <h2 className="text-[#3D3D3D] text-2xl font-black">1976 Shelby Mustang</h2>
            <p className="text-[#3D3D3D]">
              Watch this video about the Kendall Custom 1967 Shelby Mustang being built using our very own Dynacorn
              Classic Body Shell.
            </p>
          </div>
          <div className="h-96 w-full flex justify-start items-start">
          <iframe 
          className=""
          width="560" height="315" 
          src="https://www.youtube.com/embed/lbys2AuyMck?si=ylgNw12dZeK6Mv3L" 
          title="YouTube video player" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" >

          </iframe>
          </div>
        </div>
      </div>
      </div>

    </div>
  );
};

export default ClassicBodyPage;
