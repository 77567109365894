import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router";
import CartItem from "./components/cartItem";
import { useContext, useState } from "react";
import { AppContext } from "@/context/context";
import { useMutation, useQuery } from "react-query";
import { QueryKeys, checkout, checkoutDealer, getCurrentItemsQuantity, getItemsInCart, specialsProducts } from "@/lib/queries";
import { Product } from "@/interfaces/product.interface";
import { getPriceAgainstLevel } from "@/utils/helpers/getPriceAgainstLevel";
import { Dealer } from "@/interfaces/dealer.interface";
import Loader from "@/components/Loader";
import PaymentOption from "@/assets/images/paymentmethod.png";

const CartPage = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const dealerUser = state.user as Dealer;
  const isDealer = state.user && (state.user as Dealer).account;

  const [specialPrices, setSpecialPrices] = useState<{ [key: string]: { salePrice: number; orgPrice: number } }>({});

  // Fetch cart items
  const { data: carts, isLoading: isCartsLoading } = useQuery(
    QueryKeys.CART,
    () =>
      getItemsInCart({
        user: state.user?.id as number,
        type: dealerUser && dealerUser.account ? 2 : 1,
      }),
    {
      enabled: !!state.user,
      refetchOnWindowFocus: false,
    }
  );

  // Fetch item quantities
  const { data: quantity } = useQuery(
    QueryKeys.ITEMS_QUANTITY,
    () =>
      getCurrentItemsQuantity({
        user: state.user?.id as number,
        type: dealerUser && dealerUser.account ? 2 : 1,
      }),
    {
      enabled: !!state.user,
      refetchOnWindowFocus: false,
    }
  );

  // Fetch special prices for non-dealers
  useQuery(QueryKeys.SPECIAL, specialsProducts, {
    enabled: !isDealer, // Fetch only for non-dealers
    onSuccess: (data) => {
      const specialsMap = data?.item.reduce((acc, product) => {
        acc[product.item] = {
          salePrice: product.sale_price || 0,
          orgPrice: product.org_price || 0,
        };
        return acc;
      }, {} as { [key: string]: { salePrice: number; orgPrice: number } });
      setSpecialPrices(specialsMap);
    },
  });

  const getItemQuantity = (item: string) => {
    const itemQuantity = quantity?.quantity.find((q) => q.item === item);
    return itemQuantity?.quantity || 0;
  };

  // Fetch the price of the product
  const fetchPrice = (product: Product) => {
    if (isDealer) {
      const dealerType = Number(dealerUser?.dealer_info?.type) || 0;
      return getPriceAgainstLevel(dealerType, product);
    } else {
      const specialPriceData = specialPrices[product.item];
      if (specialPriceData?.salePrice) {
        return specialPriceData.salePrice ?? 0; // Return sale price if special price exists
      } else if (product.onsale) {
        return (product.pricel ?? 0) * 0.9; // Return promo price if on sale
      } else {
        return product.pricel ?? 0; // Regular price as fallback
      }
    }
  };
  

  // Calculate subtotal
  const subtotal = () => {
    let total = 0;
    
    carts?.carts.forEach((cart) => {
      const basePrice = fetchPrice(cart);
      const itemQuantity = getItemQuantity(cart.item);
      total += basePrice * itemQuantity;
    });

    return total;
  };

  const mutation = useMutation(checkout, {
    onSuccess() {
      navigate("/confirm-order");
    },
  });

  const dealerMutation = useMutation(checkoutDealer, {
    onSuccess() {
      navigate("/dealer-confirm-order");
    },
  });

  const handleCheckout = () => {
    if (subtotal() <= 0) {
      alert("Your cart is empty");
      return;
    }

    if (dealerUser && dealerUser.account) {
      dealerMutation.mutate(dealerUser.id as number);
    } else {
      mutation.mutate(state.user?.id as number);
    }
  };

  if (isCartsLoading) {
    return <Loader />;
  }

  return (
    <div className="container mx-auto my-20 mt-40">
      <div className="flex justify-center items-center flex-col gap-5">
        <Button className="bg-[#FFE512] hover:bg-[#FFE512] text-black rounded-lg px-8" onClick={() => navigate("/")}>
          Return to Home
        </Button>
      </div>
      <h1 className="text-center font-black text-[#3D3D3D] text-3xl mt-8 mb-16">Shopping Cart</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        <div className="col-span-2 space-y-12">
          {carts &&
            carts?.carts.length > 0 &&
            carts.carts.map((cart, index) => {
              const specialPriceData = specialPrices[cart.item];
              const specialPrice = specialPriceData?.salePrice ? specialPriceData.salePrice : undefined;
              
              return (
                <CartItem
                  key={index}
                  code={cart.item}
                  name={cart.descrip}
                  image={cart.img_path}
                  price={fetchPrice(cart) as number}
                  specialPrice={specialPrice} // Pass down special price if exists
                  quantity={getItemQuantity(cart.item)}
                  stock={cart.onhand}
                  yearFit={`${cart.year_from}-${cart.year_end}`}
                  make={cart.make}
                  onhand={cart.onhand}
                  orderpt={parseInt(cart.orderpt)}
                />
              );
            })}
        </div>
        <div className="col-span-1">
          <div className="bg-[#3D3D3D] text-white rounded-[31px] py-2 text-center">
            <h2 className="text-lg font-semibold">Order Summary</h2>
          </div>
          <div className="p-4 mx-5 space-y-2 shadow-md shadow-gray-400">
            <div className="flex justify-between">
              <span className="text-gray-600">Subtotal:</span>
              <span className="font-medium text-gray-900">${subtotal().toFixed(2)} CAD</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Shipping:</span>
              <span className="font-medium text-gray-900">-</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Tax:</span>
              <span className="font-medium text-gray-900">-</span>
            </div>
            <div className="border-[#3D3D3D] my-4 border-t-2"></div>
            <div className="flex justify-between">
              <span className="text-lg font-bold text-gray-900">Total:</span>
              <span className="text-lg font-bold text-gray-900">${subtotal().toFixed(2)} CAD</span>
            </div>
          </div>
          <div className="mt-10 flex justify-center">
            <Button
              className="bg-[#184178] h-14 hover:bg-[#184178] rounded-2xl text-lg text-white font-bold w-3/5"
              onClick={handleCheckout}
              disabled={subtotal() <= 0}
            >
              Check out
            </Button>
          </div>
          <div className="mt-14 text-center border shadow-md shadow-gray-400 py-4 h-40 mx-4">
            <h3 className="text-gray-700 font-medium">Payment methods</h3>
            <img src={PaymentOption} alt="Payment options: paypal, mastercard, visa" className="object-contain mx-auto h-[7rem]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
