import PromotionBanner from "@/assets/images/2024_GLA_banner_save.jpg";
import ProductDetailDialog from "@/components/dialog/productDetailDialog";
import SectionCard from "@/components/sectionCard";
import SectionTitle from "@/components/sectionTitle";
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import { IMAGE_URL } from "@/config/appConfig";
import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";
import { Product } from "@/interfaces/product.interface";
import { QueryKeys, promotionalProducts } from "@/lib/queries";
import { getPriceAgainstLevel } from "@/utils/helpers/getPriceAgainstLevel";
import { useContext, useState } from "react";
import { useQuery, useQueryClient } from "react-query";

const Promotion = () => {
  const [productId, setProductId] = useState<string>("");
  const [showProductDialog, setShowProductDialog] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { state } = useContext(AppContext);

  const dealerUser = state.user && (state.user as Dealer);

  const { data: promotionProducts } = useQuery(QueryKeys.PROMOTIONAL_PRODUCTS, () => promotionalProducts(), {
    refetchOnWindowFocus: false,
  });

  const isDealer = state.user && (state.user as Dealer).account;
  const fetchPrice = (product: Product) => {
    if (isDealer) {
      // If user is a dealer, get the price level based on the dealer's type
      const dealerType = Number(dealerUser?.dealer_info?.type) || 0;
      return getPriceAgainstLevel(dealerType, product);
    } else {
      // If user is not a dealer, return the regular price
      return product.pricel;
    }
  };
  
  return (
    <div className="my-5 px-4 md:px-6">
      <img src={PromotionBanner} alt="Promotion Banner" className="w-full h-auto" />
      <SectionTitle
        title="On Discount"
        subTitle="Promotional"
        category="Products"
        onRefresh={() => {
          queryClient.invalidateQueries(QueryKeys.PROMOTIONAL_PRODUCTS);
        }}
      />
      <Carousel className="my-5" opts={{ align: "start" }}>
        <CarouselContent>
          {promotionProducts &&
            promotionProducts.length > 0 &&
            promotionProducts.map((product, index) => (
              <CarouselItem key={index} className="sm:basis-1/2 basis-full">
                <SectionCard
                  className="h-[475px]"
                  code={product.item}
                  title={product.descrip}
                  year={product.year_from + "-" + product.year_end}
                  price={`CAD ${fetchPrice(product).toFixed(2)}`}
                  promoPrice={!isDealer ? `CAD ${(fetchPrice(product) * 0.9).toFixed(2)}` : ""}
                  image={IMAGE_URL + product.img_path}
                  onClick={() => {
                    setProductId(product.item);
                    setShowProductDialog(true);
                  }}
                />
              </CarouselItem>
            ))}
        </CarouselContent>
      </Carousel>
      {showProductDialog && (
        <ProductDetailDialog
          open={showProductDialog}
          onClose={() => setShowProductDialog(false)}
          productCode={productId}
        />
      )}
    </div>
  );
};

export default Promotion;
