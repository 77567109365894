import ProductDetailDialog from "@/components/dialog/productDetailDialog";
import SectionCard from "@/components/sectionCard";
import { useContext, useState } from "react";
import FilterProduct from "../products/components/filterProduct";
import { IMAGE_URL } from "@/config/appConfig";
import { truncate } from "@/lib/utils";
import { useQuery } from "react-query";
import { QueryKeys, fetchPromotionsProducts } from "@/lib/queries";
import { PaginationComponent } from "../specials/components/paginationComponent";
import { Product } from "@/interfaces/product.interface";
import { getPriceAgainstLevel } from "@/utils/helpers/getPriceAgainstLevel";
import { AppContext } from "@/context/context";
import { Dealer } from "@/interfaces/dealer.interface";
import { Loader } from "lucide-react";

const PromotionPage = () => {
  const [productId, setProductId] = useState<string>("");
  const [showProductDialog, setShowProductDialog] = useState<boolean>(false);
  const [pages, setPages] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [make, setMake] = useState<string>("");
  const [year, setYear] = useState<number | null>(null); // Use null as the initial state
  const [item, setItem] = useState<string>(""); // Added item state
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

  const { state } = useContext(AppContext);
  const dealerUser = state.user && (state.user as Dealer);

  // Adjust query key and function to handle null values for year
  const { data, isLoading } = useQuery(
    [QueryKeys.PROMOTIONS, year, make, currentPage, item],
    () => fetchPromotionsProducts(currentPage, make, year || 0, item),
    {
      refetchOnWindowFocus: false,
      enabled: filtersApplied || year !== null, // Fetch only if filters are applied or year is not null
      onSuccess: (data) => {
        setCurrentPage(data.items.current_page);
        const totalPages = Math.ceil(data.items.total / 20);
        const pages = [...Array(totalPages).keys()].map((i) => i + 1);
        setPages(pages);
      },
    }
  );

  const handleMakeChange = (selectedMake: string) => {
    setMake(selectedMake);
    setFiltersApplied(true);
  };

  const handleYearChange = (selectedYear: number) => {
    setYear(selectedYear);
    setFiltersApplied(true);
  };

  const handleSearch = (selectedYear: number, selectedItem: string) => {
    setYear(selectedYear); 
    setItem(selectedItem);
    setFiltersApplied(true);
  };

  const fetchPrice = (product: Product) => {
    const isDealer = dealerUser && dealerUser.account;
    const priceLevel = getPriceAgainstLevel((dealerUser && (dealerUser.level as number)) || 0, product);
    return isDealer ? priceLevel : product.pricel;
  };

  return (
    <div className="mx-[8%] my-36 px-4 md:px-8">
      <FilterProduct
        open={true}
        make={make}
        year={year || 0}
        item={item}
        handleMakeChange={handleMakeChange}
        handleYearChange={handleYearChange}
        handleSearch={handleSearch}
      />
      <p className="text-center italic text-[#3D3D3D] text-sm mb-5 font-bold z-10">
        {data && data.items.total} products found
      </p>

      {isLoading && (
        <div className="w-full h-full flex items-center justify-center">
          <Loader className="animate-spin h-16 w-16" />
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-fit mx-auto">
        {data &&
          data.items &&
          data.items.data.length > 0 &&
          data.items.data.map((product, index) => (
            <SectionCard
              key={index}
              code={product.item}
              price={`CAD ${fetchPrice(product).toFixed(2)}`}
              promoPrice={`CAD ${(fetchPrice(product) * 0.9).toFixed(2)}`}
              title={truncate(product.descrip, 20)}
              make={product.make}
              year={product.year_from + "-" + product.year_end}
              image={IMAGE_URL + product.img_path}
              imageClassName=""
              className=""
              onClick={() => {
                setProductId(product.item);
                setShowProductDialog(true);
              }}
            />
          ))}
      </div>

      {data && data.items.data.length > 0 && (
        <PaginationComponent currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={pages.length} />
      )}

      {showProductDialog && (
        <ProductDetailDialog
          open={showProductDialog}
          onClose={() => setShowProductDialog(false)}
          productCode={productId}
        />
      )}
    </div>
  );
};

export default PromotionPage;
