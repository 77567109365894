import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { QueryKeys, finishOrderPaypalQuote, getOneOrder, storeOrderData} from "@/lib/queries";
import { Button } from "@/components/ui/button";
import OrderSummary from "./components/OrderSummary";
import OrderTable from "./components/OrderTable";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import visaMasterIMG from "@/assets/images/paymentVisaMaster.png";
import { OneOrderAddress, UserInfo } from "@/interfaces/user.interface";
import { DealerInfo } from "@/interfaces/dealer.interface";
import PaypalMG from "@/assets/images/paymentPaypal.png";
import { AppContext } from "@/context/context";
import Loader from "./components/Loader";


//Bambora Service
import { getToken, initiatePayment } from '@/services/bamboraService'; // Assuming you've set up these service functions

type CardInputEvent = React.ChangeEvent<HTMLInputElement>;

const PendingOrderPaymentPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const [policy, setPolicy] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState('Visa/Mastercard');
  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpiryMonth, setCardExpiryMonth] = useState('');
  const [cardExpiryYear, setCardExpiryYear] = useState('');
  const [cardCvc, setCardCvc] = useState('');
  const {state, dispatch} = useContext(AppContext);

  const order = state.order;

  const searchParams = new URLSearchParams(location.search);
  
  const orderSono = searchParams.get("so");
  const userId = searchParams.get("id");

  const { data: orderData } = useQuery(
    [QueryKeys.ONE_ORDER, orderSono, userId],
    () => getOneOrder(Number(orderSono), Number(userId)),
    {
      enabled: !!orderSono && !!userId,
    }
  );

  const cartItems = orderData?.oneOrder.map((item: any) => ({
    description: item.descrip,
    code: item.item,
    make: item.make,
    quantity: item.qty,
    price: item.price,
  })) || [];

  // Type guard to check if the user info is of type UserInfo
  const isUserInfo = (info: UserInfo | DealerInfo | null): info is UserInfo => {
    return (info as UserInfo).m_forename !== undefined; // Check for a property unique to UserInfo
  };

  const userInfo = isUserInfo(state.userInfo) ? state.userInfo : {} as UserInfo;

    // Shipping address logic
    const shippingAddress: OneOrderAddress = orderData?.address && orderData.somast.address !== 0 
    ? orderData.address 
    : {
        forename: userInfo.m_forename,
        surname: userInfo.m_surname,
        address: userInfo.m_address,
        city: userInfo.m_city,
        state: userInfo.m_state,
        zipcode: userInfo.m_zipcode,
        country: userInfo.m_country,
        tel: userInfo.m_tel,
      };

  const getUserIP = async (): Promise<string> => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip; // Returns the user's IP address as a string
    } catch (error) {
      console.error('Failed to get IP address:', error);
      return '0.0.0.0'; // Fallback IP if something goes wrong
    }
  };

  const handlePlaceOrder = async () => {
    if (!policy) {
      alert("You must agree to the policy before placing an order.");
      return;
    }
  
    setIsLoading(true); // Start loading
  
    try {
      // Ensure orderSono is not null before proceeding
      if (!orderSono) {
        alert("Order number is missing. Please try again.");
        setIsLoading(false);
        return;
      }
  
      // Prepare card data
      const cardData = {
        number: cardNumber.replace(/\s/g, ''),
        expiry_month: cardExpiryMonth,
        expiry_year: cardExpiryYear, // Ensure full year format
        cvd: cardCvc,
      };
  
      // Step 1: Get token from Bambora
      const tokenResponse = await getToken(cardData);
      console.log('Token received:', tokenResponse);
  
      if (tokenResponse && tokenResponse.token) {
        const userIP = await getUserIP(); // Fetch the dynamic IP address
  
        // Step 2: Prepare payment data
        const paymentData = {
          order_number: orderSono || '',  // Ensure this is a string by adding fallback
          amount: order?.total || 0,
          payment_method: "token",
          customer_ip: userIP, // Use the dynamic IP here
          term_url: "https://retail.goldenleafautomotive.com/order-receipt",
          billing: {
            name: `${order.firstname ?? ''} ${order.lastname ?? ''}`,
            address_line1: order.address1 ?? '',
            city: order.city ?? '',
            province: order.province ?? '',
            postal_code: order.postalcode ?? '',
            country: order.country ?? '',
            phone_number: order.phone ?? '',
          },
          shipping: {
            name: `${order?.firstName ?? ''} ${order?.lastName ?? ''}`,
            address_line1: order?.address ?? '',
            city: order?.city ?? '',
            province: order?.province ?? '',
            postal_code: order?.postalCode ?? '',
            country: order?.country ?? '',
            phone_number: order?.tel ?? '',
          },
          token: {
            code: tokenResponse.token,
            name: cardName,
            complete: true,
            "3d_secure": {
              browser: {
                accept_header: navigator.languages?.join(',') || '',
                java_enabled: navigator.javaEnabled(),
                language: navigator.language,
                color_depth: screen.colorDepth.toString(),
                screen_height: screen.height,
                screen_width: screen.width,
                time_zone: new Date().getTimezoneOffset(),
                user_agent: navigator.userAgent,
                javascript_enabled: true
              },
              enabled: true,
              version: 2,
              auth_required: false
            }
          }
        };
  
        console.log('Payment data to send:', paymentData);
  
        // Step 3: Process payment
        const paymentResponse = await initiatePayment(paymentData);
        console.log('Payment response:', paymentResponse);
  
        if (paymentResponse.approved === "1") {
          // Payment successful, finalize the order
  
          const orderdata = {
            orderNumber: parseInt(orderSono),  // Use `orderSono` safely since it's already validated
            custno: state.user?.id || 0,
            addressID: order?.addressID,
            pickupInstore: order?.storePickup ? true : false,
            subtotal: order?.subtotal?.toFixed(2) || "0.00",
            hst: order?.tax || 0,
            shipping: order?.shipping || 0,
            shippingDays: order?.shippingDays || 0,
            notes: order?.notes || "No additional notes",
          };
          await storeOrderData(orderdata);
          dispatch({ type: "SET_ORDER_SONO", payload: paymentResponse.order_number });
          console.log('Payment success! Redirecting...');
          navigate(`/order-receipt`);
        } else {
          alert("Payment failed. Please try again.");
        }
      } else {
        alert("Failed to tokenize card details. Please try again or use another card.");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      alert("An error occurred during payment. Please try again.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  


  const formatCardNumber = (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim()
      .substring(0, 19);
  };

  const handleCardNameChange = (e: CardInputEvent) => {
    setCardName(e.target.value);
  };

  const handleCardNumberChange = (e: CardInputEvent) => {
    setCardNumber(formatCardNumber(e.target.value));
  };

  const handleCardExpiryMonthChange = (e: CardInputEvent) => {
    setCardExpiryMonth(e.target.value.replace(/\D/g, '').substring(0, 2));
  };

  const handleCardExpiryYearChange = (e: CardInputEvent) => {
    setCardExpiryYear(e.target.value.replace(/\D/g, '').substring(0, 2));
  };

  const handleCardCvcChange = (e: CardInputEvent) => {
    setCardCvc(e.target.value.replace(/\D/g, '').substring(0, 8));
  };

  const paypalInitialOptions = {
    clientId: "AVhX7zpFGtDzUZGTcIBxN5wVF9c1tIZ0hk9ecLgw_-zBVDVuRYi1_O1k_5Jher2D9PUT7wgwWoUGFxrp",
    currency: "CAD",
    intent: "capture",
  };

  const onCreateOrder = (data: any, actions: any) => {
    console.log(data);
    const totalValue = order?.total ? Number(order.total).toFixed(2) : '0.00';
  
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              currency_code: "CAD",
              value: totalValue,
            },
          },
        ],
      });
  };
  

  const onApproveOrder = async (data: any, actions: any) => {
    console.log(data);
    try {
      const details = await actions.order.capture();
      console.log("Paypal payment success!");
      console.log(details);
  
      const payload = {
        custno: Number(userId || state.user?.id) || 0,
        sono: orderSono || ""
      };
  
      console.log("Payload to be sent:", payload);
  
      const orderResponse = await finishOrderPaypalQuote(payload);
      console.log("Order Response:", orderResponse);
  
      // Use the order number and m_id to pass them in the URL
      const { order_num} = orderResponse.result;

      dispatch({ type: "SET_ORDER_SONO", payload: order_num });
  
      navigate(`/order-receipt`);
    } catch (error) {
      console.error("Error processing PayPal order:", error);
    }
  };

  return (
    <>
    {isLoading && <Loader />}
    <div className="container mx-auto my-44">
      <h1 className="text-center font-black text-[#3D3D3D] text-3xl my-5">Payment</h1>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="col-span-2 space-y-5">
          <OrderTable items={cartItems} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-xl font-bold">Shipping to</h4>
              </div>
              <div className="bg-white shadow-md shadow-gray-400 py-6 px-4 rounded-lg space-y-2">
                <p className="text-base font-semibold">{shippingAddress.forename} {shippingAddress.surname}</p>
                <p>
                  {shippingAddress.address}, <br /> {shippingAddress.city}, {shippingAddress.state} <br /> 
                  {shippingAddress.zipcode} <br />
                  {shippingAddress.country}
                </p>
                <p>{shippingAddress.tel}</p>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-xl font-bold">Billing to</h2>
              </div>
              <div className="bg-white shadow-md shadow-gray-400 py-6 px-4 rounded-lg space-y-2">
                <p className="text-base font-semibold">{order.firstname} {order.lastname}</p>
                <p>
                  {order.address1}, <br /> {order.city}, {order.province} <br />
                  {order.postalcode} <br />
                  {order.country}
                </p>
                <p>{order.phone}</p>
              </div>
            </div>
          </div>

          <h2 className="text-xl font-bold">Payment Options</h2>
          <div className="bg-white shadow-md shadow-gray-400 py-10 px-10 rounded-lg space-y-2 mt-5">
            <div className="space-y-4">
              <div className="flex space-x-4">
                <button
                  className={`px-4 rounded-xl ${paymentMethod === 'Visa/Mastercard' ? 'bg-[#FFE512] text-white' : 'bg-gray-200'}`}
                  onClick={() => {
                    setPaymentMethod('Visa/Mastercard');
                    setPolicy(false);
                  }}
                >
                  <img src={visaMasterIMG} alt="Payment options: Visa/Mastercard" className="object-contain mx-auto h-14" />
                </button>
                <button
                  className={`px-4 rounded-xl ${paymentMethod === 'Paypal' ? 'bg-[#FFE512] text-white' : 'bg-gray-200'}`}
                  onClick={() => {
                    setPaymentMethod('Paypal');
                    setPolicy(true);
                  }}
                >
                  <img src={PaypalMG} alt="Payment options: Paypal" className="object-contain mx-auto h-14" />
                </button>
              </div>
              {paymentMethod === 'Visa/Mastercard' && (
                <div className="space-y-4 mt-4">
                  <div>
                    <p className="text-lg font-bold">
                      Card Information -- We do not store your credit card details.
                    </p>
                  </div>
                  <div className="pt-12">
                    <label htmlFor="cardName" className="block text-sm font-bold text-gray-700">Card Holder's Name</label>
                    <input
                      id="cardName"
                      type="text"
                      placeholder="Card Holder's Name"
                      value={cardName}
                      onChange={handleCardNameChange}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label htmlFor="cardNumber" className="block text-sm font-bold text-gray-700">Card Number</label>
                    <input
                      id="cardNumber"
                      type="text"
                      placeholder="Card Number"
                      value={cardNumber}
                      onChange={handleCardNumberChange}
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="flex space-x-40">
                    <div className="w-1/2">
                      <label htmlFor="cardExpiry" className="block text-sm font-bold text-gray-700">Card Expiry Date</label>
                      <div className="flex space-x-4">
                        <input
                          id="cardExpiryMonth"
                          type="text"
                          placeholder="MM"
                          value={cardExpiryMonth}
                          onChange={handleCardExpiryMonthChange}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        <span className="self-center">/</span>
                        <input
                          id="cardExpiryYear"
                          type="text"
                          placeholder="YY"
                          value={cardExpiryYear}
                          onChange={handleCardExpiryYearChange}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="w-1/2">
                      <label htmlFor="cardCvc" className="block text-sm font-bold text-gray-700">Card CVV</label>
                      <input
                        id="cardCvc"
                        type="text"
                        placeholder="Card CVV"
                        value={cardCvc}
                        onChange={handleCardCvcChange}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              )}
              {paymentMethod === 'Paypal' && (
                <div className="space-y-4 mt-4">
                  <div>
                    <p className="text-lg font-bold">
                      Pay with Paypal and Accept our shipping policy.
                    </p>
                  </div>
                  <div>
                    <PayPalScriptProvider options={paypalInitialOptions}>
                      <PayPalButtons
                        style={{ layout: "horizontal", shape: "pill", label: "checkout" }}
                        createOrder={(data, actions) => onCreateOrder(data, actions)}
                        onApprove={(data, actions) => onApproveOrder(data, actions)}
                      />
                    </PayPalScriptProvider>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <OrderSummary
            policy={policy}
            setPolicy={setPolicy}
            subtotal={orderData?.somast.subtotal}
            shipping={orderData?.somast.shipping}
            tax={orderData?.somast.tax}
            total={order.total}
          />
          {paymentMethod === 'Visa/Mastercard' && (
            <div className="mt-4 flex justify-center gap-3">
              <Button
                className="bg-[#184178] h-12 hover:bg-[#184178] rounded-[25px] text-md text-white font-bold w-4/5"
                type="button"
                onClick={handlePlaceOrder}
              >
                Place Order
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default PendingOrderPaymentPage;
