import { useContext } from "react";
import { useQuery } from "react-query";
import { useSearchParams, useNavigate } from "react-router-dom";
import { QueryKeys, getOneOrder, deleteOrder } from "@/lib/queries";
import { Button } from "@/components/ui/button";
import OrderTable from "./components/OrderTable";
import OrderSummary from "./components/OrderSummary";
import { AppContext } from "@/context/context";
import { BillingInfo, OneOrderAddress, UserInfo } from "@/interfaces/user.interface";
import { DealerInfo } from "@/interfaces/dealer.interface";
import { OneOrderProduct } from "@/interfaces/product.interface";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

// Type guard to check if the user info is of type UserInfo
const isUserInfo = (info: UserInfo | DealerInfo | null): info is UserInfo => {
  return (info as UserInfo).m_forename !== undefined; // Check for a property unique to UserInfo
};

const PendingOrderDetailsPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);

  const userIdFromUrl = searchParams.get("id");
  const orderSonoFromUrl = searchParams.get("so");

  const userId = userIdFromUrl || state.user?.id;
  const orderSono = orderSonoFromUrl || state.orderSono;

  const { data: orderData } = useQuery(
    [QueryKeys.ONE_ORDER, orderSono, userId],
    () => getOneOrder(Number(orderSono), Number(userId)),
    {
      enabled: !!orderSono && !!userId,
    }
  );

  // Safely cast userInfo to UserInfo using the type guard
  const userInfo = isUserInfo(state.userInfo) ? state.userInfo : {} as UserInfo;
  const billingInfo: BillingInfo = state?.billingInfo || ({} as BillingInfo);
  const carts: OneOrderProduct[] = orderData?.oneOrder || [];
  const subtotal = orderData?.somast.subtotal || 0;
  const taxTotal = orderData?.somast.tax || 0;
  const totalShipping = orderData?.somast.shipping || 0;
  const total = subtotal + taxTotal + totalShipping;
  const orderDate = orderData?.somast.date_order || 0;

  // Format the date to YYYY-MM-DD
  const formattedOrderDate = new Date(orderDate).toLocaleDateString('en-CA');

  // Shipping address logic
  const shippingAddress: OneOrderAddress = orderData?.address && orderData.somast.address !== 0 
    ? orderData.address 
    : {
        forename: userInfo.m_forename,
        surname: userInfo.m_surname,
        address: userInfo.m_address,
        city: userInfo.m_city,
        state: userInfo.m_state,
        zipcode: userInfo.m_zipcode,
        country: userInfo.m_country,
        tel: userInfo.m_tel,
      };

  // Store pickup logic
  const storePickup = orderData?.somast.address || 0;

  const handleMakePayment = () => {
    if (orderData?.somast.sales_status !== 5) return;

    // Dispatch the order details
    dispatch({
      type: "SET_ORDER",
      payload: {
        subtotal,
        tax: taxTotal,
        shipping: totalShipping,
        total: total,
        items: carts,
        storePickup: storePickup ? 1 : 0,
        addressID: orderData.somast.address,
        firstname: billingInfo.firstname,
        lastname: billingInfo.lastname,
        phone: billingInfo.phone,
        address1: billingInfo.address1,
        city: billingInfo.city,
        province: billingInfo.province,
        postalcode: billingInfo.postalcode,
        country: billingInfo.country
      },
    });

    // Navigate to the payment page
    navigate(`/pending-order-payment?so=${orderSono}&id=${userId}`);
  };

  const handleDeleteOrder = async () => {
    const payload = {
      sono: String(orderSono || "")
    };
  
    try {
      const deleteOrderResponse = await deleteOrder(payload);
      console.log("Order Response:", deleteOrderResponse);
      navigate("/profile?tab=Pending Orders");
    } catch (error) {
      console.error("Failed to delete order:", error);
    }
  };

  const isPaymentEnabled = orderData?.somast.sales_status === 5;

  return (
    <div className="container mx-auto my-44">
      <h1 className="text-center font-black text-[#3D3D3D] text-3xl my-5">Pending Order {orderSono}</h1>
      <h1 className="text-center font-black text-[#3D3D3D] text-2xl my-5">Order Date: {formattedOrderDate}</h1>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <div className="col-span-2 space-y-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <div className="flex justify-between items-center mb-2 h-10 rounded-3xl bg-[#3D3D3D]">
                <h4 className="text-lg font-semibold text-white ml-4">Shipping to</h4>
              </div>
              <div className="bg-white shadow-md shadow-gray-400 py-6 px-4 mx-4 rounded-lg space-y-2">
                <p className="text-base font-semibold">{shippingAddress.forename} {shippingAddress.surname}</p>
                <p>
                  {shippingAddress.address}, <br /> {shippingAddress.city}, {shippingAddress.state}, {shippingAddress.zipcode} <br />
                  {shippingAddress.country}
                </p>
                <p>{shippingAddress.tel}</p>
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center mb-2 h-10 rounded-3xl bg-[#3D3D3D]">
                <h2 className="text-lg font-semibold text-white ml-4">Billing to</h2>
              </div>
              <div className="bg-white shadow-md shadow-gray-400 py-6 px-4 mx-4 rounded-lg space-y-2">
                <p className="text-base font-semibold">{billingInfo.firstname} {billingInfo.lastname}</p>
                <p>
                  {billingInfo.address1}, <br /> {billingInfo.city}, {billingInfo.province}, {billingInfo.postalcode} <br />
                  {billingInfo.country}
                </p>
                <p>{billingInfo.phone}</p>
              </div>
            </div>
          </div>
          <OrderTable items={carts} />
        </div>
        <div className="col-span-1">
          <OrderSummary
            subtotal={subtotal}
            shipping={totalShipping}
            tax={taxTotal}
            total={total}
          />
        </div>
      </div>

      <div className="mt-20 auto-mx flex justify-center gap-3 grid grid-cols-1">
        <div className="grid grid-cols-2">
          <Button
            className={`h-12 rounded-2xl text-md text-white font-bold mx-10 ${
              isPaymentEnabled ? "bg-[#67C23A] hover:bg-[#5ADE18]" : "bg-gray-400 cursor-not-allowed"
            }`}
            type="button"
            onClick={handleMakePayment}
            disabled={!isPaymentEnabled}
          >
            Make Payment
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                className="bg-[#F56C6C] h-12 hover:bg-[#FF4545] rounded-2xl text-md text-white font-bold w-4/5"
                type="button"
              >
                Delete Order
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle className="text-center">Warning</AlertDialogTitle>
                <AlertDialogDescription>This will permanently delete the order. Continue?</AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={handleDeleteOrder}>Delete</AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
        <div className="grid grid-cols-2 mt-3">
          <Button
            className="bg-[#184178] h-12 hover:bg-[#6A9EE6] rounded-2xl text-md text-white font-bold mx-10"
            type="button"
            onClick={() => navigate("/profile?tab=Order History")}
          >
            Order History
          </Button>
          <Button
            className="bg-white hover:bg-gray-100 border-2 border-[#184178] h-12 rounded-2xl text-md text-[#184178] font-black w-4/5 shadow-md"
            type="button"
            onClick={() => navigate("/")}
          >
            Back to Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PendingOrderDetailsPage;
