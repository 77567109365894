import { HeartIcon, Menu, SearchIcon, ShoppingCartIcon, UserCircle, X } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Input } from "../ui/input";
import Logo from "@/assets/images/Logo.png";
import { Button } from "../ui/button";
import { useContext, useState } from "react";
import SearchProductsDialog from "./searchProductsDialog";
import { cn } from "@/lib/utils";
import { AppContext } from "@/context/context";
import { useQuery } from "react-query";
import { QueryKeys, getCurrentItemsQuantity } from "@/lib/queries";
import MobileNav from "./mobileNav";
import { Dealer } from "@/interfaces/dealer.interface";
import { UserInfo } from "@/interfaces/user.interface";

const Header = () => {
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const { state } = useContext(AppContext);
  
  // Fetch cart quantity using the new query
  const { data: quantityData } = useQuery(
    QueryKeys.ITEMS_QUANTITY,
    () => getCurrentItemsQuantity({
      user: state.user?.id as number,
      type: state.user && (state.user as Dealer).account ? 2 : 1
    }),
    {
      enabled: !!state.user,
      refetchOnWindowFocus: false,
    }
  );

  // Calculate total quantity in the cart
  const totalCartQuantity = quantityData ? quantityData.quantity.reduce((total, item) => total + item.quantity, 0) : 0;

  const dealerInfo = state.user && (state.user as Dealer);
  const userInfo = state.userInfo && (state.userInfo as UserInfo);

  return (
    <header className="flex h-32 w-full items-center justify-between px-4 md:px-6 shadow-2xl shadow-gray-500 z-50 bg-white fixed">
      <div className="container mx-auto flex items-center pt-14 mb-2 justify-between ">
        <button
          className="flex items-center space-x-2 ml-2 md:hidden"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          {showMobileMenu ? <X /> : <Menu />}
        </button>

        {/* Store Logo */}
        <div className="flex-shrink-0 hidden sm:flex">
          <Link className="flex items-center gap-2 text-lg font-semibold" to="/">
            <img src={Logo} alt="" className="w-auto h-16 lg:h-16" />
            <span className="sr-only">Golden Leaf Automotive</span>
          </Link>
        </div>

        {/* Search Bar */}
        <div className="flex-grow max-w-lg mx-4">
          <div className="relative" onClick={() => setOpenSearchDialog(true)}>
            <Input
              type="text"
              className="w-full h-10 border border-gray-300 rounded-[13px] pl-4 pr-10 py-2 focus:outline-none bg-[#D9D9D9] focus-visible:ring-0 focus-visible:ring-offset-0 hidden md:block"
              placeholder="Enter a specific part or category"
            />
            <span className="absolute rounded-[13px] right-0 top-1/2 transform -translate-y-1/2 md:bg-white py-[12px] px-3 cursor-pointer bg-[#D9D9D9]">
              <SearchIcon className="text-black h-4 w-4 " />
            </span>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          {/* Profile Button */}
          {state.user && state.user && state.billingInfo ? (
            <Link to="/profile">
              <Button variant={"secondary"} className="flex items-center gap-4 bg-[#FFE512] rounded-[12px] h-12">
                <UserCircle className="w-4 h-4" />
                {userInfo && userInfo.m_forename + " " + userInfo.m_surname}
              </Button>
            </Link>
          ) : dealerInfo && dealerInfo.account ? (
            <Link to="/profile">
              <Button
                variant={"secondary"}
                className="flex items-center gap-4 bg-[#184178] hover:bg-[#184178] rounded-[12px] text-white h-12"
              >
                <UserCircle className="w-4 h-4" />
                {dealerInfo.name}
              </Button>
            </Link>
          ) : (
            <Link to="/sign-in">
              <Button
                variant={"secondary"}
                className="flex items-center gap-4 bg-[#D9D9D9] rounded-[12px] hover:bg-[#FFE512] h-12"
              >
                <UserCircle className="w-4 h-4" />
                Profile
              </Button>
            </Link>
          )}
          
          {/* Wishlist Button */}
          <Button
            className={cn(
              "p-3 rounded-[12px] text-black hover:bg-[#FFE512] h-12 w-12",
              pathname === "/wishlist" ? "bg-[#FFE512]" : "bg-[#D9D9D9]",
              !state.user && "pointer-events-none bg-[#D9D9D9] opacity-50"
            )}
            onClick={() => navigate("/wishlist")}
          >
            <HeartIcon className="w-4 h-4" />
          </Button>

          {/* Shopping Cart Button */}
          <Link to={!state.user ? "#" : "/cart"} className={!state.user ? "pointer-events-none" : ""}>
            <div className="relative">
              {/* Display total cart quantity */}
              {totalCartQuantity > 0 && (
                <p className="absolute -right-2 bg-[#FC3333] h-5 w-5 text-center rounded-full -top-2 text-white text-[12px] flex justify-center items-center">
                  {totalCartQuantity}
                </p>
              )}
              <Button
                variant={"secondary"}
                className={cn(
                  "bg-[#D9D9D9] rounded-[12px] p-3 h-12 w-12",
                  pathname === "/cart" && "bg-[#FC3333]",
                  totalCartQuantity > 0 && "bg-[#184178] text-white hover:bg-[#6A9EE6]",
                  totalCartQuantity === 0 && "bg-[#D9D9D9] hover:bg-[#FFE512]",
                  !state.user && "pointer-events-none opacity-50"
                )}
              >
                <ShoppingCartIcon className="w-4 h-4" />
              </Button>
            </div>
          </Link>
        </div>
      </div>
      {showMobileMenu && <MobileNav setCloseMenu={(bool) => setShowMobileMenu(bool)} />}
      {openSearchDialog && <SearchProductsDialog open={openSearchDialog} onClose={() => setOpenSearchDialog(false)} />}
    </header>
  );
};

export default Header;
