import { cn } from "@/lib/utils";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "@/assets/images/Logo.png";
import { ROUTES } from "@/utils/constants";
import { useLockBody } from "@/lib/hooks/useLockBody";

interface MobileNavProps {
  children?: React.ReactNode;
  setCloseMenu: (bool: boolean) => void;
}

const MobileNav = ({ children, setCloseMenu }: MobileNavProps) => {
  useLockBody();
  return (
    <div
      className={cn(
        "fixed inset-0 top-16 z-50 grid h-[calc(100vh-4rem)] grid-flow-row auto-rows-max overflow-auto p-6 pb-32 shadow-md animate-in slide-in-from-bottom-80 lg:hidden"
      )}
    >
      <div className="relative z-20 grid gap-3 rounded-md bg-popover p-4 text-popover-foreground shadow-md">
        <Link
          to="/"
          className="items-center space-x-2 flex justify-center h-[32px] pr-4 w-auto"
          onClick={() => setCloseMenu(false)}
        >
          <img src={Logo} alt="" className="w-auto h-8 lg:h-10" />
        </Link>
        <hr className="m-0" />
        <nav className="grid grid-flow-row auto-rows-max text-sm">
          {ROUTES.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className={cn("flex w-full items-center rounded-md p-2 text-sm font-medium hover:underline capitalize")}
              onClick={() => setCloseMenu(false)}
            >
              {item.name}
            </Link>
          ))}
        </nav>
        {children}
      </div>
    </div>
  );
};

export default MobileNav;
