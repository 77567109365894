type Route = {
  name: string;
  path: string;
};

type Routes = Route[];

export const ROUTES: Routes = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "All Products",
    path: "/all-products",
  },
  {
    name: "Specials",
    path: "/specials",
  },
  {
    name: "Promotions",
    path: "/promotions",
  },
  {
    name: "Classic Body",
    path: "/classic-body",
  },
  {
    name: "Catalog",
    path: "/catalog",
  },
  {
    name: "Contact Us",
    path: "/contact-us",
  },
];

export const CATALOG: Routes = [
  {
    name: "View Catalog",
    path: "/catalog",
  },
];

export type tab =
  | "About Me"
  | "Order History"
  | "Pending Orders"
  | "Submit Inquiry"
  | "Download Price Sheet"
  | "Change Password"
  | "Logout";

export const TABS: tab[] = [
  "About Me",
  "Order History",
  "Pending Orders",
  "Submit Inquiry",
  "Download Price Sheet",
  "Change Password",
];

export const EXCLUDE_ROUTES = [
  "/sign-in",
  "/register",
  "/forgetpassword",
  "/customer-info",
  "/all-products/:productId/:productCode",
  "/profile",
  "/classic-body/:model/:modelCode",
  "/privacy-policy",
];

export type PrivacyPolicy = "Pricing Policy" | "Return Policy" | "Shipping and Delivery" | "Warranty and Damage";

export const PRIVACY_POLICY: PrivacyPolicy[] = [
  "Pricing Policy",
  "Return Policy",
  "Shipping and Delivery",
  "Warranty and Damage",
];

export const CANADA_PROVINCES = [
  { label: "Alberta", value: "AB" },
  { label: "British Columbia", value: "BC" },
  { label: "Manitoba", value: "MB" },
  { label: "New Brunswick", value: "NB" },
  { label: "Newfoundland and Labrador", value: "NL" },
  { label: "Nova Scotia", value: "NS" },
  { label: "Ontario", value: "ON" },
  { label: "Prince Edward Island", value: "PE" },
  { label: "Quebec", value: "QC" },
  { label: "Saskatchewan", value: "SK" },
];

export const USA_STATES = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];
