import { useLockBody } from "@/lib/hooks/useLockBody";
import { cn } from "@/lib/utils";
import { tab } from "@/utils/constants";

interface MobileNavProps {
  children?: React.ReactNode;
  setCloseMenu: (bool: boolean) => void;
  tabs: tab[];
  activeTab: tab;
  setActiveTab: (tab: tab) => void;
  logout: () => void;
}

const MobileNav = ({ children, setCloseMenu, tabs, activeTab, setActiveTab, logout }: MobileNavProps) => {
  useLockBody();
  return (
    <div
      className={cn(
        "fixed inset-0 top-16 z-50 grid h-[calc(100vh-4rem)] grid-flow-row auto-rows-max overflow-auto p-6 pb-32 shadow-md animate-in slide-in-from-bottom-80 lg:hidden bg-white"
      )}
    >
      <div className=" relative z-20 grid gap-3 rounded-md bg-white p-4 text-black shadow-md">
        <nav className="grid grid-flow-row auto-rows-max text-sm">
          {tabs.map((item, index) => (
            <p
              key={index}
              className={cn(
                "flex w-full items-center rounded-md p-2 text-sm font-medium hover:underline capitalize cursor-pointer",
                activeTab === item ? "text-[#E5B80B] font-bold" : ""
              )}
              onClick={() => {
                setActiveTab(item);
                setCloseMenu(false);
              }}
            >
              {item}
            </p>
          ))}

          <p
            className="flex w-full items-center rounded-md p-2 text-sm font-medium hover:underline capitalize cursor-pointer"
            onClick={() => {
              logout();
              setCloseMenu(false);
            }}
          >
            Logout
          </p>
        </nav>
        {children}
      </div>
    </div>
  );
};

export default MobileNav;
