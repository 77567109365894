import { IMAGE_URL } from "@/config/appConfig";
import { AppContext } from "@/context/context";
import { QueryKeys, updateQuantity } from "@/lib/queries";
import { X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Dealer } from "@/interfaces/dealer.interface";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

interface CartItemProps {
  code: string;
  name: string;
  image: string;
  yearFit: string;
  stock: number;
  quantity: number;
  price: number;
  specialPrice?: number; // Add specialPrice as an optional prop
  onhand: number;
  orderpt: number;
  make: string;
}

const CartItem = ({
  code,
  name,
  image,
  yearFit,
  stock,
  quantity,
  price,
  specialPrice, // Handle specialPrice here
  onhand,
  orderpt,
  make,
}: CartItemProps) => {
  const { state } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [currentQuantity, setCurrentQuantity] = useState<number>(quantity);
  const [loading, setLoading] = useState(true);
  const dealerUser = state.user as Dealer;
  const isDealer = dealerUser && dealerUser.account;

  const mutation = useMutation(updateQuantity, {
    onSuccess(data) {
      if (data.status === "updated") {
        queryClient.invalidateQueries(QueryKeys.ITEMS_QUANTITY);
        queryClient.invalidateQueries(QueryKeys.CART);
      }
    },
  });

  const updateItemQuantity = async () => {
    await mutation.mutateAsync({
      user: state.user?.id as number,
      type: isDealer ? 2 : 1,
      item: code,
      quantity: currentQuantity.toString(),
    });
  };

  useEffect(() => {
    if (quantity) {
      setCurrentQuantity(quantity);
      setLoading(false);
    }
  }, [quantity]);

  const deleteItem = async () => {
    mutation.mutate({
      quantity: 0,
      user: state.user?.id as number,
      type: isDealer ? 2 : 1,
      item: code,
    });
  };

  // Display price: prioritize special price, then promo, and finally regular
// Safely handle displayPrice to avoid calling .toFixed() on undefined
const displayPrice = loading
  ? " "
  : specialPrice !== undefined && specialPrice !== null
  ? Number(specialPrice).toFixed(2)
  : Number(price ?? 0).toFixed(2);  // Ensure price defaults to 0 if undefined

// Safely calculate promoPrice, use 0 if price is undefined
const promoPrice = price ? Number(price) * 0.9 : 0;

// Safely calculate totalPrice, ensuring no undefined values are used
const totalPrice = loading
  ? " "
  : isDealer
    ? (Number(price ?? 0) * currentQuantity).toFixed(2)  // Ensure price defaults to 0 if undefined
    : specialPrice !== undefined && specialPrice !== null
    ? (Number(specialPrice) * currentQuantity).toFixed(2)
    : (Number(price ?? 0) * currentQuantity).toFixed(2);  // Fallback to 0 if price is undefined



  console.log({ price, specialPrice, promoPrice, totalPrice });


  return (
    <div className="max-w-2xl mx-auto bg-white shadow-md shadow-gray-400 rounded-2xl border border-gray-200 relative mx-[8%]">
      <div className="absolute -top-5 -right-2 p-1 cursor-pointer">
        <AlertDialog>
          <AlertDialogTrigger>
            <div className="w-8 h-8 bg-[#FC3333] rounded-full flex items-center justify-center">
              <X className="w-6 h-6 font-black text-white" />
            </div>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle className="text-center">Warning</AlertDialogTitle>
              <AlertDialogDescription>Are you sure to delete the item from shopping cart?</AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={deleteItem}>Delete</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
      <div className="p-2 px-4 flex justify-between items-start gap-5 mt-3">
        <div className="flex-shrink-0 flex flex-col items-center gap-5">
          <div className="text-lg font-black text-[#184178]">{code}</div>
          <img src={IMAGE_URL + image} alt="Product Image" className="object-center object-contain h-28 w-10/12" />
        </div>
        <div>
          <div className="text-lg font-semibold">{name}</div>
          <div className="text-sm mt-4">
            <div>
              Year fit: <span className="font-semibold">{yearFit}</span>
            </div>
            <div>
              Compatible make: <span className="font-semibold">{make}</span>
            </div>
          </div>
          <div className="text-green-600 font-semibold mt-2">In-stock: {stock}</div>
        </div>
      </div>
      <div className="px-4 py-3 flex justify-between items-center">
        <div className="flex items-center">
          <label htmlFor="quantity" className="mr-2 ml-10 font-black text-[#3D3D3D]">Quantity:</label>
          <input
            type="number"
            id="quantity"
            name="quantity"
            value={currentQuantity}
            onChange={(e) => {
              if (stock >= parseInt(e.target.value)) {
                setCurrentQuantity(parseInt(e.target.value));
              }
            }}
            className="w-12 border border-gray-300 rounded p-1"
          />
          <button className="ml-2 text-blue-500" onClick={updateItemQuantity}>Update</button>
        </div>
        <div className="text-right">
          {isDealer ? (
            <div className="text-sm">
              Unit price: <span className="font-black text-lg ml-1">CAD {displayPrice}</span>
            </div>
          ) : (
            <div>
              {/* Special price logic */}
              {specialPrice ? (
                <div>
                  <div className="text-sm text-red-600">
                    Special price: <span className="font-black text-lg ml-1">CAD {specialPrice.toFixed(2)}</span> {/* Special price */}
                  </div>
                </div>
              ) : onhand > orderpt ? (
                <div>
                  <div className="text-sm">
                    <span className="line-through">CAD {price.toFixed(2)}</span> {/* Crossed out original price */}
                  </div>
                  <div className="text-sm text-red-600">
                    Promo price: <span className="font-black text-lg ml-1">CAD {promoPrice.toFixed(2)}</span> {/* Promo price */}
                  </div>
                </div>
              ) : (
                <div className="text-sm">
                  Unit price: <span className="font-black text-lg ml-1">CAD {price.toFixed(2)}</span> {/* Regular price */}
                </div>
              )}
            </div>
          )}
          <div className="text-sm">
            Total price: <span className="font-black text-lg ml-1">CAD {totalPrice}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
